import React from "react"
import styled from "styled-components"

import { VerticalSection } from "components/common"

import { featureColors } from "utils"

const SupplyChainStayFocused = ({ hat, title, subtitle, media }) => {
  return (
    <StyledSupplyChainStayFocused>
      <VerticalSection
        hat={hat}
        titleAs="h2"
        title={title}
        subtitle={subtitle}
        media={media}
        mobileTgOrder="1"
        mobileFull
      />
    </StyledSupplyChainStayFocused>
  )
}

const StyledSupplyChainStayFocused = styled.div`
  --tg-hat-color: ${featureColors.supplyChain};
  --tg-max-width: 566px;
`

export default SupplyChainStayFocused
