import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Layout, Seo, Nav } from "components/common"
import { useContentfulSection, useMeta } from "utils"

import {
  SupplyChainHero,
  SupplyChainTeam,
  SupplyChainCustomerSuccess,
  SupplyChainKnowledge,
  SupplyChainStayFocused,
  SupplyChainWeListen,
  SupplyChainEasyStuff,
} from "src/sections/features/supplyChain"

import ExploreFeatures from "src/sections/features/ExploreFeatures"
import { CtaGetDemo } from "components/cta"

const SupplyChainPage = ({ data: { supplyChain } }) => {
  const {
    supplyChain_hero,
    supplyChain_team,
    supplyChain_customerSuccess,
    supplyChain_knowledge,
    supplyChain_stayFocused,
    supplyChain_easyStuff,
    supplyChain_weListen,
    explore_more_features,
    cta_get_demo,
  } = useContentfulSection(supplyChain.sections)

  const meta = useMeta(supplyChain)

  return (
    <Layout>
      <Seo {...meta} />
      <Wrap>
        <Nav />
        <SupplyChainHero {...supplyChain_hero} />
        <SupplyChainTeam {...supplyChain_team} />
        <SupplyChainCustomerSuccess {...supplyChain_customerSuccess} />
        <SupplyChainKnowledge {...supplyChain_knowledge} />
        <SupplyChainStayFocused {...supplyChain_stayFocused} />
        <SupplyChainWeListen {...supplyChain_weListen} />
        <SupplyChainEasyStuff {...supplyChain_easyStuff} />
        <ExploreFeatures {...explore_more_features} />
        <CtaGetDemo {...cta_get_demo} />
      </Wrap>
    </Layout>
  )
}

const Wrap = styled.div``

export const query = graphql`
  {
    supplyChain: contentfulPage(pageId: { eq: "supplyChain" }) {
      ...Page
    }
  }
`

export default SupplyChainPage
