import React from "react"
import styled from "styled-components"

import { HSection, Grid, ValueProp } from "components/common"

import { device, featureGlass, featureColors } from "utils"

const SupplyChainKnowledge = ({ hat, title, subtitle, media, valueProps }) => {
  const renderGrid = () => (
    <Grid
      col={1}
      gap={32}
      align="center"
      width="100%"
      list={valueProps}
      component={(vp) => <ValueProp orientation="horizontal" {...vp} />}
    />
  )

  return (
    <StyledSupplyChainKnowledge>
      <HSection
        hat={hat}
        title={title}
        titleAs="h2"
        subtitle={subtitle}
        media={media}
        grid={renderGrid}
        mobileFull
      />
    </StyledSupplyChainKnowledge>
  )
}

const StyledSupplyChainKnowledge = styled.div`
  --tg-hat-color: ${featureColors.supplyChain};
  --section-max-width: 1080px;
  --h-section-columns: 2fr 1fr;

  ${featureGlass};

  @media ${device.laptop} {
    --tg-max-width: 650px;
  }

  .vp_contentWrap {
    --tg-max-width: 500px;
    grid-template-columns: 22px 1fr;

    .image {
      padding-top: 3px;
    }
    .subtitle {
      font-weight: 400;
      line-height: 32px;
    }
  }
`

export default SupplyChainKnowledge
