import React from "react"
import styled from "styled-components"

import { Link } from "gatsby"

import map from "lodash/map"

import { VerticalSection, TextGroup, FeatureIcon } from "components/common"

import { device } from "utils"

const ExploreFeatures = ({ hat, title, subtitle }) => {
  const exploreIcons = [
    {
      id: "supplyChain",
      text: "SUPPLY-CHAIN",
      to: "/product/supply-chain",
      color: "var(--cannabis)",
    },
    {
      id: "compliance",
      text: "COMPLIANCE",
      to: "/product/compliance",
      color: "var(--forest)",
    },
    {
      id: "finances",
      text: "FINANCES",
      to: "/product/finances",
      color: "var(--moss)",
    },
    {
      id: "productivity",
      text: "PRODUCTIVITY",
      to: "/product/productivity",
      color: "var(--seafoam)",
    },

    {
      id: "profitability",
      text: "PROFITABILITY",
      to: "/product/profitability",
      color: "var(--shoreline)",
    },
    {
      id: "customization",
      text: "CUSTOMIZATION",
      to: "/product/customization",
      color: "var(--shoreline)",
    },
    {
      id: "integrations",
      text: "INTEGRATIONS",
      to: "/integrations",
      color: "var(--blueberry)",
    },
  ]

  const renderGrid = () => {
    return (
      <ul>
        {map(exploreIcons, ({ id, to, text, color }) => (
          <ExploreItem key={id} color={color}>
            <Link to={to}>
              <FeatureIcon id={id} />
              <TextGroup hat={text} />
            </Link>
          </ExploreItem>
        ))}
      </ul>
    )
  }

  return (
    <StyledExploreFeatures className="explore_features">
      <VerticalSection
        hat={hat}
        titleAs="h2"
        title={title}
        subtitle={subtitle}
        grid={renderGrid}
      />
    </StyledExploreFeatures>
  )
}

const StyledExploreFeatures = styled.div`
  --tg-max-width: 570px;

  ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 1080px;
    margin: 0 auto;
    grid-row-gap: var(--sp-16);

    @media ${device.laptop} {
      grid-row-gap: 0;
      grid-template-columns: repeat(7, auto);
    }
  }
`

const ExploreItem = styled.li`
  cursor: pointer;

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  svg {
    /* overflow: visible; */
    width: 48px;
    height: 48px;
    #icon,
    #bg {
      transition: 0.1s transform ease-in-out;
      will-change: transform;
    }

    &:hover {
      #icon {
        transform: scale(1.05) translate3d(-4px, -4px, 0);
      }
      #bg {
        transform: scale(0.95) translate3d(4px, 4px, 0);
      }
    }
  }

  .hat {
    font-size: 12px;
    text-align: center;
    margin-top: var(--sp-4);
    --tg-hat-color: ${({ color }) => color};

    @media ${device.laptop} {
      margin-top: var(--sp-8);
    }
  }
`

export default ExploreFeatures
