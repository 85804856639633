import React from "react"
import styled from "styled-components"

import { VerticalSection, Grid, ValueProp } from "components/common"

import { featureGlass, featureColors } from "utils"

const SupplyChainTeam = ({ hat, title, subtitle, media, valueProps }) => {
  const renderGrid = () => (
    <Grid
      col={3}
      gap={56}
      align="center"
      width="250px"
      list={valueProps}
      component={(vp) => <ValueProp align="center" {...vp} />}
    />
  )

  return (
    <StyledSupplyChainTeam>
      <VerticalSection
        hat={hat}
        titleAs="h2"
        title={title}
        subtitle={subtitle}
        media={media}
        mobileTgOrder="1"
        grid={renderGrid}
        mobileFull
      />
    </StyledSupplyChainTeam>
  )
}

const StyledSupplyChainTeam = styled.div`
  --tg-hat-color: ${featureColors.supplyChain};
  --tg-max-width: 635px;
  ${featureGlass};

  .vp_contentWrap {
    .subtitle {
      font-weight: 400;
      line-height: 32px;
    }
  }
`

export default SupplyChainTeam
