import React from "react"
import styled from "styled-components"

import { VerticalSection, Grid, ValueProp } from "components/common"

import { featureColors, glassStyle } from "utils"

const SupplyChainCustomerSuccess = ({
  hat,
  title,
  subtitle,
  media,
  valueProps,
}) => {
  const renderGrid = () => (
    <Grid
      col={2}
      gap={32}
      align="center"
      width="340px"
      list={valueProps}
      component={(vp) => <ValueProp align="center" {...vp} />}
    />
  )

  return (
    <StyledSupplyChainCustomerSuccess>
      <VerticalSection
        hat={hat}
        titleAs="h2"
        title={title}
        subtitle={subtitle}
        // media={media}
        mobileTgOrder="1"
        grid={renderGrid}
      />
    </StyledSupplyChainCustomerSuccess>
  )
}

const StyledSupplyChainCustomerSuccess = styled.div`
  --tg-hat-color: ${featureColors.supplyChain};
  /* --tg-max-width: 535px; */

  .vp_contentWrap {
    ${glassStyle};
    padding: var(--sp-48) var(--sp-24);
    .subtitle {
      font-weight: 400;
      line-height: 32px;
    }
  }
`

export default SupplyChainCustomerSuccess
