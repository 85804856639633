import React from "react"
import styled from "styled-components"

import { VerticalSection, Grid, ValueProp } from "components/common"

import { featureColors } from "utils"

const SupplyChainEasyStuff = ({ hat, title, subtitle, valueProps, media }) => {
  const renderGrid = () => (
    <Grid
      col={2}
      gap={56}
      align="center"
      width="300px"
      list={valueProps}
      component={(vp) => <ValueProp orientation="horizontal" {...vp} />}
    />
  )

  return (
    <StyledSupplyChainEasyStuff>
      <VerticalSection
        hat={hat}
        titleAs="h2"
        title={title}
        subtitle={subtitle}
        media={media}
        mobileTgOrder="1"
        grid={renderGrid}
        mobileFull
      />
    </StyledSupplyChainEasyStuff>
  )
}

const StyledSupplyChainEasyStuff = styled.div`
  --tg-hat-color: ${featureColors.supplyChain};
  --tg-max-width: 700px;
  --section-padding-bottom: 0;
  --section-mobile-padding-bottom: 0;

  .vp_contentWrap {
    .image {
      padding-top: 3px;
    }
    .subtitle {
      font-weight: 400;
      line-height: 32px;
    }
  }
`

export default SupplyChainEasyStuff
